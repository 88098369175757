/* .markdown span  {
    color: #df0e0e; 
    font-style: italic;
    padding : 0;
  }
  

  .markdown span strong {
    color: inherit;
    font-style: normal;
  }
   */

#btnCalendly + br {
  display: none;
}
.icon {
    height: 28px;
    width: 28px;
  }
  
  @media (max-width: 768px) {
    .icon {
      height: 40px;
      width: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .icon {
      height: 40px;
      width: 40px;
    }
  }
  
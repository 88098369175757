.main-layout {
  display: flex;
  position: relative;
  margin-top :20px;
}

.hamburger {
  position: absolute;
  top: 0px;
  left: 15px;
  cursor: pointer;
  z-index: 1000;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 190px;
  max-width: 80%; /* Ajustable pour écrans étroits */
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  z-index: 999;
  transform: translateX(-100%); /* Initialement masqué */
  transition: transform 0.3s ease; /* Transition fluide */
}

.sidebar.open {
  transform: translateX(0); /* Visible when open */
}

.content {
  flex: 1;
  padding: 20px;
  /* Adjust this if you want to shift content when the sidebar is open */
  /* margin-left: 250px;  */
  width: 100%;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .content {
    margin-left: 0; /* Don't shift content on small screens */
  }
}


.specter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  z-index: 1000;
  overflow: hidden;
}

.sidebar .pro-menu-item {
  transition: background-color 0.3s ease;
}

.sidebar .pro-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Change background on hover */
}

.iconeReset{
  top : 20px;
  left :89vw;
}
.iconeReset2{
  top : 15px;
  left :89vw;
}

/* Styles pour ajouter des bordures au tableau */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  text-align: left;
}

th, td {
  border: 1px solid #dddddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Optionnel : Pour centrer le tableau */
table {
  margin-left: auto;
  margin-right: auto;
}


/* Style pour l'indicateur de chargement circulaire */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Couleur de fond du spinner */
  border-radius: 50%;
  border-top: 4px solid rgb(0, 0, 0); /* Couleur du bord supérieur du spinner */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  align-items: center;
  
  
}
.spin{
  z-index: 100;
  display: flex;
  /* border : 2px solid black; */
  justify-content: center;
}


/* Animation pour le spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

